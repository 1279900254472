
    import { VueAgile } from 'vue-agile';
    export default {
        name: 'TrustedBySection',
        components: {
            agile: VueAgile,
        },
        data() {
            return {
                options: {
                    autoplay: true,
                    navButtons: false,
                    autoplaySpeed: 2000,
                    dots: false,
                    infinite: true,
                    responsive: [
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 2,
                            },
                        },
                        {
                            breakpoint: 900,
                            settings: {
                                slidesToShow: 5,
                            },
                        },
                    ],
                },
            };
        },
        computed: {
            trustedByData() {
                return this.$store.state.trustedByData;
            },
        },
    };
